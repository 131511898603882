import { useState } from 'react'
import styled from 'styled-components'
import { HomeSectionBannerResponseDto } from '@/apis/rest/generated/types'
import { ButtonSlideScrollLeft, ButtonSlideScrollRight } from '@/components/common/buttons'
import { CarouselBase } from '@/components/common/carousels/CarouselBase'
import { FitpetIcon } from '@/components/common/icons/fitpet-icon'
import { CarouselBanner } from '@/components/domains/home/new/carousel-banner'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { SCREEN_MEDIA_QUERY, Z_INDEXES } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { useCustomRouter } from '@/containers/hooks'

interface HomeBannerTopRollProps {
  homeSectionItem: HomeSectionBannerResponseDto
}

export const HomeBannerTopRoll = ({ homeSectionItem }: HomeBannerTopRollProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const onIndexChange = (currentIdx: number) => {
    setCurrentIndex(currentIdx)
  }

  return (
    <StyledContainer>
      <CarouselBase
        onIndexChange={onIndexChange}
        slidesToShow={1}
        autoplay
        arrowTopPosition={160}
        NextArrow={<RenderNextArrow />}
        PreviousArrow={<RenderPrevArrow />}
      >
        {homeSectionItem.imageTypeBanners?.map((banner, index) => (
          <CarouselBanner key={banner.id} banner={banner} index={index} />
        ))}
      </CarouselBase>
      <RightTopButtonsContainer>
        <RenderPagination carouselIndex={currentIndex} totalCount={homeSectionItem.imageTypeBanners?.length || 0} />
      </RightTopButtonsContainer>
    </StyledContainer>
  )
}

const RenderPagination = ({ carouselIndex, totalCount }: { carouselIndex: number; totalCount: number }) => {
  const router = useCustomRouter()
  return (
    <StyledPagination onClick={() => router.push(ROUTES.HOME.EVENTS.BASE)}>
      <StyledPaginationText>{`${carouselIndex + 1} / ${totalCount}`}</StyledPaginationText>
      <FitpetIcon type="ic-add" color={GlobalColorEnum.GrayWhite} size={IconSizeEnum.Size12} />
    </StyledPagination>
  )
}

const RenderNextArrow = (props: any) => {
  const { onClick } = props
  return (
    <NextArrowButtonContainer>
      <ButtonSlideScrollRight size={'medium'} visible onClick={onClick} />
    </NextArrowButtonContainer>
  )
}

const RenderPrevArrow = (props: any) => {
  const { onClick } = props
  return (
    <PrevArrowButtonContainer>
      <ButtonSlideScrollLeft size={'medium'} visible onClick={onClick} />
    </PrevArrowButtonContainer>
  )
}

const StyledContainer = styled.div`
  position: relative;
  line-height: 0;
`

const RightTopButtonsContainer = styled.div`
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;
`

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  width: 7.2rem;
  height: 2.8rem;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 1.3rem;
  cursor: pointer;
  padding-left: 0.2rem;
  z-index: ${Z_INDEXES.CAROUSEL_HOME_EVENT};
`

const StyledPaginationText = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.color.grayWhite};
`

const NextArrowButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${Z_INDEXES.CAROUSEL_ARROW_BUTTON};
  cursor: pointer;
  @media ${SCREEN_MEDIA_QUERY.xs} {
    display: none;
  }
`

const PrevArrowButtonContainer = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${Z_INDEXES.CAROUSEL_ARROW_BUTTON};
  cursor: pointer;
  @media ${SCREEN_MEDIA_QUERY.xs} {
    display: none;
  }
`
