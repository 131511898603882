import { useEffect, useRef, useState } from 'react'
import { useDebounceCallback } from '@react-hook/debounce'
import { useDispatch } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { Z_INDEXES } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter, usePetType } from '@/containers/hooks'
import { AdPetType } from '@/graphql/generated/schemas'
import { Bridge } from '@/utils/bridge/bridge'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import appBridgeProvider, { isInAppFlag } from '@/utils/utilBridge'
import { getPetTypeRootCategoryKoreanText } from '@/utils/utilCategory'
import { localShowPetTypeTooltip } from '@/utils/utilLocalStorage'

const RotateDuration = 100
const DebounceTime = 500

export const PetTypeSelectButton = () => {
  const tracker = useTracker()
  const { replace } = useCustomRouter()
  const { setPetType, getPetType } = usePetType()
  const currentPetType = getPetType()
  const changeIconRef = useRef<HTMLDivElement | null>(null)
  const [isShowToolTip, setIsShowToolTip] = useState(false)
  const dispatch = useDispatch()

  const handleChangePetType = useDebounceCallback(() => {
    changeIconRef.current?.classList.remove('rotateAnimation')
    setPetType(AdPetType.Dog === currentPetType ? AdPetType.Cat : AdPetType.Dog)

    if (isInAppFlag) {
      appBridgeProvider((bridge: Bridge) => bridge.setting({ petTypeMall: getPetType().toLocaleLowerCase() }))
    }

    replace(ROUTES.INDEX)

    setTimeout(() => {
      changeIconRef.current?.classList.add('rotateAnimation')
    }, RotateDuration)
  }, DebounceTime)

  useEffect(() => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickGnbPetToggle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPetType])

  useEffect(() => {
    if (!localShowPetTypeTooltip.load()) {
      localShowPetTypeTooltip.save('SHOWING_PET_TYPE_TOOLTIP')
      setIsShowToolTip(true)
    }

    return () => {
      setIsShowToolTip(false)
    }
  }, [])

  return (
    <StyledPetTypeButton onClick={handleChangePetType}>
      <StyledPetTypeText>{getPetTypeRootCategoryKoreanText(currentPetType as AdPetType)}</StyledPetTypeText>
      <StyledChangeIconWrapper ref={changeIconRef}>
        <FlatIcon type={IconNameEnum.IcChange} size={IconSizeEnum.Size24} />
      </StyledChangeIconWrapper>
      {isShowToolTip && <StyledPetTypeTooltip>우리 아이에게 맞는 상품을 볼 수 있어요.</StyledPetTypeTooltip>}
    </StyledPetTypeButton>
  )
}

const rotateIcon = keyframes`
  100% {
    transform: rotate(360deg)
  }
`

const fadeIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
  
`
const fadeOut = keyframes`
  
  100% {
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
  }
`

const StyledPetTypeButton = styled.div`
  position: relative;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 1.6rem;
  background-color: transparent;
  transition: all 0.1s linear;

  &::before {
    visibility: hidden;
    opacity: 0;
    content: '';
    position: absolute;
    width: 7.7rem;
    height: 3.4rem;
    border-radius: 17px;
    transition: all 50ms linear;
    background-color: ${(props) => props.theme.color.gray100};
  }

  &:active {
    &::before {
      visibility: visible;
      opacity: 1;
    }
  }
`

const StyledChangeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.rotateAnimation {
    animation: ${rotateIcon} 0.5s ease-in-out;
    transform-origin: 50% 50%;
  }
`

const StyledPetTypeText = styled.button`
  position: relative;
  font-size: 1.6rem;
  color: ${(props) => props.theme.color.gray900};
  font-weight: 700;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`

const StyledPetTypeTooltip = styled.div`
  position: absolute;
  z-index: ${Z_INDEXES.HEADER};
  left: 1.2rem;
  bottom: -4.2rem;
  transform-origin: 0 0;
  transform: scale(0);
  opacity: 0;
  animation: 0.5s Cubic-Bezier(0.9, -0.01, 0.2, 1) 3s forwards ${fadeIn}, 0.18s ease-in-out 6.68s forwards ${fadeOut};
  cursor: default;
  width: 23.6rem;
  padding: 0.8rem 0;
  border-radius: 8px;
  color: ${(props) => props.theme.color.grayWhite};
  background-color: ${(props) => props.theme.color.blue800};
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    z-index: ${Z_INDEXES.HEADER};
    left: 12px;
    top: -7px;
    width: 14px;
    height: 8px;
    background-color: ${(props) => props.theme.color.blue800};
    clip-path: path('M6.24742 0.860087C6.64583 0.40476 7.35416 0.404761 7.75258 0.860088L14 8L0 8L6.24742 0.860087Z');
  }
`
