import { useCallback } from 'react'
import { match } from 'ts-pattern'
import { useGetHomeSections } from '@/apis/rest/generated/hooks'
import { HomeSectionTypeEnum } from '@/apis/rest/generated/types'
import { HomeSectionBanners } from '@/components/domains/home/new/home-section-banners'
import { HomeSectionBestReview } from '@/components/domains/home/new/home-section-best-review'
import { HomeSectionDisplayCollection } from '@/components/domains/home/new/home-section-display-collection'
import { HomeSectionCategory } from '@/components/domains/home/new/home-section-list/category-section/home-section-category'
import { AnchorBadgeButton } from '@/components/domains/home/new/home-section-list/components/anchor-badge-button'
import { HomeSectionBrand } from '@/components/domains/home/new/home-section-list/components/home-section-brand'
import { HomeSectionFloatingButton } from '@/components/domains/home/new/home-section-list/components/home-section-floating-button'
import { useHomeSectionAnchorBadge } from '@/components/domains/home/new/home-section-list/hooks/use-home-section-anchor-badge'
import { HomeSectionRecommendByPetProduct } from '@/components/domains/home/new/home-section-recommend-by-pet-product'
import { HomeSectionRecommendRecentlyBoughtProduct } from '@/components/domains/home/new/home-section-recommend-recently-bought-product'
import { HomeSectionWeeklyBest } from '@/components/domains/home/new/home-section-weekly-best'
import { SkeletonHome } from '@/components/domains/home/new/skeleton-home'
import { LayoutFooter } from '@/components/domains/layouts'
import { useHomeTracker } from '@/containers/event-tracker/home.event-tracker'
import { useCustomRouter, usePetType } from '@/containers/hooks'
import { localDeviceUuid } from '@/utils/utilLocalStorage'

export const HomeSectionList = () => {
  const { trackViewHomeEvent } = useHomeTracker()

  const { query } = useCustomRouter()
  const { getPetType } = usePetType()
  const petType = getPetType()

  const { isShowsAnchorBadge, handleClickButtonAnchorBadge, handleUpdataAnchorBadge, handleViewAnchorBadge } =
    useHomeSectionAnchorBadge()

  const { data: homeSectionList, isLoading: isHomeSectionListLoading } = useGetHomeSections(
    {
      petType,
      uuid: localDeviceUuid.load(),
    },
    {
      query: {
        onSuccess: (data) => {
          if (!data) {
            return
          }
          trackViewHomeEvent(data.abGroupCode)
        },
      },
    }
  )

  const anchorRefCallback = useCallback(
    (node: HTMLDivElement) => {
      const target = query.targetSection as string
      if (node && node?.id.includes(target)) {
        location.href = `#${node.id}`
        setTimeout(() => (location.href = `#${node.id}`))
      }
    },
    [query]
  )

  const isShowBottomBadge = !!homeSectionList?.data && homeSectionList.data.length > 0 && isShowsAnchorBadge

  if (isHomeSectionListLoading || !homeSectionList?.data) {
    return <SkeletonHome />
  }

  return (
    <>
      {homeSectionList?.data?.map((homeSectionItem, index) =>
        match(homeSectionItem.homeSectionType)
          .with(HomeSectionTypeEnum.BANNER, () => {
            return (
              <section key={homeSectionItem.id}>
                <HomeSectionBanners homeSectionItem={homeSectionItem} />
              </section>
            )
          })
          // 리뷰 좋은 상품
          .with(HomeSectionTypeEnum.BEST_REVIEW, () => {
            return (
              <section key={homeSectionItem.id}>
                <HomeSectionBestReview
                  homeSectionItem={homeSectionItem}
                  refCallback={anchorRefCallback}
                  sectionIndex={index}
                />
              </section>
            )
          })
          // 브랜드 샵
          .with(HomeSectionTypeEnum.BRAND, () => {
            return (
              <section key={homeSectionItem.id}>
                <HomeSectionBrand
                  key={`${petType}${homeSectionItem.id}`}
                  petType={petType}
                  homeSectionItem={homeSectionItem}
                  refCallback={anchorRefCallback}
                />
              </section>
            )
          })
          // 카테고리
          .with(HomeSectionTypeEnum.CATEGORY, () => {
            return (
              <section key={homeSectionItem.id}>
                <HomeSectionCategory homeSectionItem={homeSectionItem} />
              </section>
            )
          })
          // 오늘의 핫딜
          // 따끈따끈 신상품
          // 매거진
          // 매거진(백그라운드 이미지)
          // 일반 타입 리스트
          .with(HomeSectionTypeEnum.DISPLAY_COLLECTION, () => {
            return (
              <section key={homeSectionItem.id}>
                <HomeSectionDisplayCollection homeSectionItem={homeSectionItem} refCallback={anchorRefCallback} />
              </section>
            )
          })
          // [pet] 에게  이 상품 어때요?
          .with(HomeSectionTypeEnum.RECOMMEND_BY_PET_PRODUCT, () => {
            return (
              <section key={homeSectionItem.id}>
                <HomeSectionRecommendByPetProduct
                  hasBadge={isShowBottomBadge}
                  sectionIndex={index}
                  onSectionView={handleViewAnchorBadge}
                  setTargetPersonalSection={handleUpdataAnchorBadge}
                />
              </section>
            )
          })
          // 최근 구매한 이 상품!
          .with(HomeSectionTypeEnum.RECOMMEND_RECENTLY_BOUGHT_PRODUCT, () => {
            return (
              <section key={homeSectionItem.id}>
                <HomeSectionRecommendRecentlyBoughtProduct
                  hasBadge={isShowBottomBadge}
                  sectionIndex={index}
                  onSectionView={handleViewAnchorBadge}
                  setTargetPersonalSection={handleUpdataAnchorBadge}
                />
              </section>
            )
          })
          .with(HomeSectionTypeEnum.RECOMMEND_RELATED_PRODUCT, () => {
            return <section key={homeSectionItem.id}></section>
          })
          .with(HomeSectionTypeEnum.WEEKLY_BEST, () => {
            return (
              <section key={homeSectionItem.id}>
                <HomeSectionWeeklyBest
                  homeSectionItem={homeSectionItem}
                  refCallback={anchorRefCallback}
                  sectionIndex={index}
                />
              </section>
            )
          })
          .with(HomeSectionTypeEnum.MAGAZINE_IMAGE, () => {
            return <section key={homeSectionItem.id}></section>
          })
          .with(HomeSectionTypeEnum.MAGAZINE_BACKGROUND_IMAGE, () => {
            return <section key={homeSectionItem.id}></section>
          })
          .with(HomeSectionTypeEnum.RECOMMEND_FREQUENTLY_BOUGHT_PRODUCT, () => {
            return <></>
          })
          .with(HomeSectionTypeEnum.RECOMMEND_BY_PET_PRODUCT_BY_PERSONALIZE, () => {
            return <></>
          })
          .exhaustive()
      )}
      {!!homeSectionList && homeSectionList.data.length > 0 && <LayoutFooter />}
      {isShowBottomBadge && <AnchorBadgeButton onClick={handleClickButtonAnchorBadge} />}
      {/* scroll top floating button */}
      <HomeSectionFloatingButton />
    </>
  )
}
